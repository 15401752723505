import React, { useRef } from 'react';

const PrivacyForm = () => {
	const ref1 = useRef(null);
	const ref2 = useRef(null);
	const ref3 = useRef(null);
	const ref4 = useRef(null);
	const ref5 = useRef(null);
	const ref6 = useRef(null);
	const ref7 = useRef(null);
	const ref8 = useRef(null);
	const ref9 = useRef(null);
	const ref10 = useRef(null);

	function scrolltoEl(e) {
		window.scrollTo(0, e.current.offsetTop - 70)  
	}

	// const executeScroll = () => scrollToRef(myRef)

	function heading(input) {
		return(
			<div style={{marginBottom:20,fontSize:24,textAlign:'center',fontWeight:'bold'}} className='flexCenter'>
				{input}
			</div>
		)
	}
	function paragraph(input) {
		return(
			<div style={{marginBottom:20}}>
				{input}
			</div>
		)
	}
	// function textDisplay(input) {
	// 	return(
	// 		<div>{input}</div>
	// 	)
	// }
	// function dotPoint(input) {
	// 	return(
	// 		<div className='flexLeft' style={{width:'100%',marginBottom:20}}>
	// 			<div style={{width:'6%',height:20}} className='flexCenter'>
	// 				<div style={{height:8,width:8,borderRadius:20,background:'black',marginLeft:10}}></div>
	// 			</div>
	// 			<div style={{width:'94%'}}>{input}</div>
	// 		</div>
	// 	)
	// }
	function LinkText(input,ref) {
		return(
			<div className='flexLeft' style={{width:'100%',textDecoration:'underline',cursor:'pointer',
					color:'rgb(60,30,255)',marginBottom:4}}
					onClick={()=>{scrolltoEl(ref)}}>
				{input}
			</div>
		)
	}
	function subheading(input) {
		return(
			<div style={{fontWeight:'bold'}}>
				{input}
			</div>
		)
	}
	return(
		<div>

			{heading('TrikTreat Privacy Policy')}
			{paragraph('EFFECTIVE DATE: 10.13.20')}
			{paragraph('TrikTreat LLC (“TrikTreat,” “Company,” “we,” or “us”) values your privacy.  Our mobile application, TrikTreat, offers the ability to participate in virtual trick or treating events.')}
			<p style={{marginBottom:20}}>
				In this Privacy Policy (“Policy”), we describe how we collect, use, and disclose information that we obtain about users of our website, www.triktreat.com (the “Site”), our mobile application (the “App”), and the services available through our Site and App (collectively, the Site, the App and the services available therein are the “Services”).  By using or downloading the App, or otherwise using the Services, you agree that your information will be handled as described in this Policy.
				Your use of our Services, and any dispute over privacy, is subject to this Policy and our <a style={{width:'100%',textDecoration:'underline',cursor:'pointer',color:'rgb(60,30,255)'}} href='/terms'>Terms of Use</a>, including its applicable limitations on damages and the resolution of disputes.
				Our <a style={{width:'100%',textDecoration:'underline',cursor:'pointer',color:'rgb(60,30,255)'}} href='/terms'>Terms of Use</a> are incorporated by reference into this Policy.</p>


			{LinkText('Collection of Information',ref1)}
			{LinkText('Use of Information',ref2)}
			{LinkText('Disclosure of Information',ref3)}
			{LinkText('Analytics and Tracking',ref4)}
			{LinkText('Third-Party Sites and Services',ref5)}
			{LinkText('Security',ref6)}
			{LinkText('Your Choices',ref7)}
			{LinkText('Children',ref8)}
			{LinkText('Changes to this Policy',ref9)}
			{LinkText('Contact Us',ref10)}

			<div style={{height:20}}></div>

			<div ref={ref1}></div>
			{subheading('Collection of Information')}
			{paragraph('We may collect information directly from you, from non-affiliated entities, and by automated means as set out below. The type of information that we collect from you depends on your particular interaction with our Services, but generally includes the below information.')}
			{paragraph('Account and Registration. Users must register a TrikTreat account in order to use our Services.  TrikTreat-ers account holders must provide information such as your name, email, password, information to confirm you are old enough to create an account, and avatar photo. We may also ask you or allow you to submit certain optional information, which may include your friends list, location (city, state), relationship to other Triktreat-ers and other account information.')}
			{paragraph(`TrikTreat account holders who are parents and/or legal guardians may also create profiles for their children and provide profile information such as name and avatar photo. Please see the section “Children” below for more information, including how we protect children's privacy online.`)}
			{paragraph('Interactions and Activities. The Services allow TrikTreat-ers to host and attend virtual trick or treating events with other TrikTreat-ers.  During events, TrikTreat-ers will be able to communicate and engage with one another via video and audio chat.  You can interact with other TrikTreat-ers through features provided in our Services such as sending virtual candy.  We collect information about your activities in the Services, such as events attended and/or hosted, ratings, flagging history, and points collected. If you would like to purchase a fee-based feature (such as a jump scare), we collect payment and billing information needed to process your payment, as well as information regarding your purchase history.')}
			{paragraph('Newsletter and Promotions.  You can sign up to receive our newsletter, promotions, updates, product information, and other information we think may interest you by providing your email address.')}
			


			{paragraph('Communications and Customer Service. We maintain records related to customer support and customer service-related requests, including the nature of the request, name and contact information of the requestor, and information related to the resolution of the request.')}
			{paragraph('Usage Data. We may automatically collect information about your use of our Services through cookies, local storage, analytics tools and other technologies, such as your mobile device ID, location information, device name and model, operating system type, name and version, the length of time that you are using our Services and your activities within our Services (“Usage Data”).  We may combine Usage Data with other information that we have collected about you.  Please see the section “Analytics and Tracking” below for more information.')}
			<div ref={ref2}></div>
			{subheading('Use of Information')}
			{paragraph('We use your information, including your personal information, for the following purposes:')}
			{paragraph('Providing and Improving Our Services. To provide and maintain our Services; to provide troubleshooting; to improve our Services; to develop new features, products, or services; to perform technical operations, such as updating software; and for other customer service and support purposes. ')}
			{paragraph('Marketing and Communications. To communicate with you about your account and use of our Services via email and push notification, including to send you product updates; to respond to your inquiries; to provide you with news and newsletters, special offers, promotions, and other information we think may interest you; and for other informational, marketing, or promotional purposes.   Please see the Your Choices section for more information about how to change your communications preferences.')}
			{paragraph('Personalizing Content and Ads. We may use the information we collect about you to personalize the information and content we display to you, including marketing and promotional content, as well as providing you with more relevant ads. ')}
			{paragraph('Research and Analytics. To better understand how users access and use our Services, and for other research and analytical purposes, such as to evaluate and improve our services and business operations and to develop additional products, services and features.')}
			
			{paragraph('Protecting Rights and Interests. To protect the safety, rights, property, or security of TrikTreat, the Services, any third party, or the general public; to detect, prevent, or otherwise address fraud, security, or technical issues; to prevent or stop activity that we consider to be, or to pose a risk of being, an illegal, unethical, or legally actionable activity; to use as evidence in litigation; and to enforce this Policy or our Terms of Use.')}
			<p style={{marginBottom:20}}>
				Protecting Rights and Interests. To protect the safety, rights, property, or security of TrikTreat, the Services, any third party, or the general public; to detect, prevent, or otherwise address fraud, security, or technical issues; to prevent or stop activity that we consider to be, or to pose a risk of being, an illegal, unethical, or legally actionable activity; to use as evidence in litigation; and to enforce this Policy or our <a style={{width:'100%',textDecoration:'underline',cursor:'pointer',color:'rgb(60,30,255)'}} href='/terms'>Terms of Use</a>.</p>

			{paragraph('Legal Compliance.  To comply with applicable legal or regulatory obligations, including as part of a judicial proceeding; to respond to a subpoena, warrant, court order, or other legal process; or as part of an investigation or request, whether formal or informal, from law enforcement or a governmental authority.')}
			{paragraph('General Business Operations.  Where appropriate for the administration of our general business, accounting, recordkeeping and legal functions.  As part of our routine business administration, such as employee training, compliance auditing and similar internal activities')}
			<div ref={ref3}></div>
			{subheading('Disclosure of Information')}
			{paragraph('In general, we may share your information, including personal information, as follows:')}
			{paragraph('Users. When attending an event, TrikTreat-er profile information such as first name and first initial of last name, avatar picture, and rating, as well as certain user activity within the Services may be viewable by other users.  Your live video and audio messages will also be viewable by other TrikTreat-ers that you chat with during events')}
			



			{paragraph('Affiliates. We may disclose the information we collect from you to our affiliates or subsidiaries; however, if we do so, their use and disclosure of your personal information will be subject to this Policy.')}
			{paragraph('Business Transfers. We may disclose your information to another entity in connection with an acquisition or merger, sale or transfer of a business unit or assets, bankruptcy proceeding, or as part of any other similar business transfer, including during negotiations related to such transactions.')}
			{paragraph('Protecting Rights and Interests. We may disclose your information to protect the safety, rights, property, or security of TrikTreat, the Services, any third party, or the general public; to detect, prevent, or otherwise address fraud, security, or technical issues; to prevent or stop activity which we, in our sole discretion, may consider to be, or to pose a risk of being, an illegal, unethical, or legally actionable activity; to use as evidence in litigation; and to enforce this Policy or Terms of Use. ')}
			{paragraph('Legal Compliance. We may disclose your information to comply with applicable legal or regulatory obligations, including as part of a judicial proceeding; in response to a subpoena, warrant, court order, or other legal process; or as part of an investigation or request, whether formal or informal, from law enforcement or a government official.')}
			{paragraph('Aggregate and De-Identified Information. We may share aggregate or de-identified information about users with third parties for research, compliance, or other purposes.')}
			<div ref={ref4}></div>
			{subheading('Analytics and Tracking')}
			{paragraph('We and our third-party service providers use cookies, local storage objects and other tracking mechanisms designed for mobile apps to track information about your use of our Services.  We may combine this information with other personal information we collect from you (and our third-party service providers may do so on our behalf). ')}
			{paragraph('Cookies.  Cookies are alphanumeric identifiers that we transfer to your device’s hard drive through the Services for record-keeping purposes. Some cookies allow us to make it easier for you to navigate our Services, while others allow us to track your activities in our Services. ')}
			{paragraph('Do-Not-Track. Currently, our systems do not recognize browser “do-not-track” requests. You may, however, disable certain tracking as discussed in this section (e.g., by disabling cookies).')}

			<p style={{marginBottom:20}}>
					Third Party Analytics. We may use non-affiliated analytics companies, for example <a style={{width:'100%',textDecoration:'underline',cursor:'pointer',color:'rgb(60,30,255)'}} href='https://policies.google.com/privacy?hl=en'>Google Analytics</a> (see <a style={{width:'100%',textDecoration:'underline',cursor:'pointer',color:'rgb(60,30,255)'}} href='https://support.google.com/analytics/answer/6004245?hl=en'>privacy policy</a> and <a style={{width:'100%',textDecoration:'underline',cursor:'pointer',color:'rgb(60,30,255)'}} href='https://tools.google.com/dlpage/gaoptout'>opt-out</a>), to evaluate use of our Services. We use these tools to help us understand use of, and to improve, our Services, performance, ad campaigns, and user experiences. These entities may use cookies and other tracking technologies, such as web beacons or local storage objects (LSOs), to perform their services.</p>



			<div ref={ref5}></div>
			{subheading('Third-Party Sites and Services')}
			{paragraph('Our Services may contain links to third-party websites or applications. Any access to and use of such linked websites is not governed by this Policy, but instead is governed by the privacy policies of those third-party websites. We are not responsible for the information practices of such third-party websites.')}
			
			<div ref={ref6}></div>
			{subheading('Security')}
			{paragraph('We have implemented safeguards to protect the personal information we collect from loss, misuse, and unauthorized access, disclosure, alteration, and destruction.  Please be aware that despite our best efforts, no data security measures can guarantee 100% security. You should take steps to protect against unauthorized access to your password, phone, and computer by, among other things, signing off after using a shared device.')}
			<div ref={ref7}></div>
			{subheading('Your Choices')}
			{paragraph('We may send periodic informational emails to you. You may opt out of such communications by following the opt-out instructions contained in the email or emailing us at communications@triktreat.com.  Please note that it may take up to ten (10) business days for us to process opt-out requests. If you opt out of receiving emails about recommendations or other information we think may interest you, we may still send you emails about any Services you have requested or received from us.')}
			<div ref={ref8}></div>
			{subheading('Children ')}
			{paragraph(`TrikTreat recognizes the importance of protecting children's privacy online and as further described below, we have implemented protections and choices to parents who consent to the use of the App on behalf of their children.`)}
			{paragraph('Personal Information.  We do not share any personal information which may be collected by the App with third parties other than service providers whose processing of the information is necessary for the delivery of the Services. The use of the App for the purposes of creating or attending an event, including when users, including a child user, chats and/or interacts via video chat, could result in the collection, use, and/disclosure of pictorial information and communications with service providers.  If a parent or guardian objects to such collection of personal information from your child, you should exercise your parental controls listed below.')}
			{paragraph('Parental Controls and Choices. We only require a child user to share the minimal personal information necessary to be able to participate in the Services we offer.  If you are a parent or guardian and wish to revoke your previously provided consent, review information collected from your child, or have that information deleted, please contact us at:')}

			<p style={{marginBottom:20}}>
					Attn: Andrew Marsh, 12444 Ventura Blvd # 203, Studio City, CA 91604 USA, <a style={{width:'100%',textDecoration:'underline',cursor:'pointer',color:'rgb(60,30,255)'}} href='mailto:privacy@triktreat.com'>privacy@triktreat.com</a>,</p>


			{paragraph(`If we make material changes to how we collect, use, share or otherwise process your child's personal information, we will notify parents by email or by other means as required by applicable law.`)}

			<p style={{marginBottom:20}}>
					Please see our <a style={{width:'100%',textDecoration:'underline',cursor:'pointer',color:'rgb(60,30,255)'}} href='/coppa'>Children’s Privacy Policy</a> for more information.</p>


			<div ref={ref9}></div>
			{subheading('Changes to this Policy')}
			{paragraph('This Policy is current as of the Effective Date set forth above.  We may change this Policy from time to time, so please be sure to check back periodically.  We will post any changes to this Policy on our Services. If we make any changes to this Policy that materially affect our practices with regard to the personal information we have previously collected from you, we will endeavor to provide you with notice in advance of such change, such as via email or prominent notice in the Services.')}
			<div ref={ref10}></div>
			{subheading('Contact Us')}
			{paragraph('If you have questions about the privacy aspects of our Services or would like to make a complaint, please contact us at communications@triktreat.com.')}

			<div style={{height:20}}></div>
			<div style={{height:20}}></div>
			<div style={{height:100}}></div>
		</div>
	)
}

export default PrivacyForm;