import React from 'react';

import HomePage from '../../Components/Home/HomePage';
import HomePageMobile from '../../Components/Home/HomePageMobile';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';

const Home = () => {
	
	if(document.documentElement.clientWidth < 700){
		return(
			<div>
				<NavBar />
				<div style={{height:50}}></div>
				<HomePageMobile />
				<div style={{position:'relative',zIndex:2}}>
					<Footer />
				</div>
			</div>
		)
	}
	return(
		<div>
			<NavBar />
			<div style={{height:50}}></div>
			<HomePage />
			<div style={{position:'relative',zIndex:2}}>
				<Footer />
			</div>
		</div>
	)
}

export default Home;