import React, { useState, useEffect } from 'react';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';

import AudioPlayer from './AudioPlayer';

import halloweenLogo from '../../Images/halloweenLOGOblack2.png';
import appStore from '../../Images/appstorelink.png';
import appIcon from '../../Images/triktreatappicon.png';
import christmasLights from '../../Images/chrstmas_lights.png';
import jollydayzlogo from '../../Images/jollydayzlogo.png';

const videoLink = 'https://firebasestorage.googleapis.com/v0/b/triktreat-landing.appspot.com/o/3pumpkins.mp4?alt=media&token=63866595-6554-4c47-ab41-55cde3e0b645';
const snowmanLink = 'https://firebasestorage.googleapis.com/v0/b/triktreat-landing.appspot.com/o/snowman.jpeg?alt=media&token=088c18ca-b0c3-4ee9-a5a4-d7b26b85aed9';
const screenshot1 = 'https://firebasestorage.googleapis.com/v0/b/triktreat-landing.appspot.com/o/triktreatscreenshot1.png?alt=media&token=aca7cb6c-c013-4990-976e-c996332a7846';
const screenshot3 = 'https://firebasestorage.googleapis.com/v0/b/triktreat-landing.appspot.com/o/triktreatscreenshot2.png?alt=media&token=9cc16bb2-2483-4b7a-9601-835c51b92cde';
const screenshot2 = 'https://firebasestorage.googleapis.com/v0/b/triktreat-landing.appspot.com/o/screen4.png?alt=media&token=329e4b08-078e-4cae-8ffa-560cfc58604e';
const screenshot4 = 'https://firebasestorage.googleapis.com/v0/b/triktreat-landing.appspot.com/o/screen3.png?alt=media&token=965620fa-7fd9-4655-a2a7-c3191fd1135b';

let elements;
let windowHeight;

const appstoreLink = 'https://apps.apple.com/us/app/triktreat/id1535405286';

const HomePage = () => {
	const [ height, setHeight ] = useState(0);
	// const [ width, setWidth ] = useState(0);
	const [ el1, setEl1 ] = useState(false);


	useEffect(()=>{

		setTimeout(()=>{
			let x = document.getElementById("videoWidth");
			x.autoplay = true;
			x.load();
		},500);
		elements = document.querySelectorAll('.riseUpStart');
    	windowHeight = window.innerHeight;
    	window.addEventListener('resize', init);
    	window.addEventListener('scroll', checkPosition);

		setHeight(document.documentElement.clientHeight);
		// setWidth(document.documentElement.clientWidth);
		setTimeout(()=>{
			setEl1(true);
		},500);
		return () => {
			// clearInterval(interval);
			window.removeEventListener('resize', init);
			window.removeEventListener('scroll', checkPosition);
		}
	},[]);

	function init() {
		setHeight(document.documentElement.clientHeight);
		// setWidth(document.documentElement.clientWidth);
	}

	function checkPosition() {
		for (var i = 0; i < elements.length; i++) {
			var element = elements[i];
			var positionFromTop = elements[i].getBoundingClientRect().top;

			if (positionFromTop - windowHeight <= 0) {
				// setTimeout(()=>{
					// element.classList.add('fadeIn');
					element.classList.add('riseUp');
					element.classList.remove('riseUpStart');
					// element.classList.remove('hidden');
				// },500);
			}
		}
	}




	function el1Display() {
		if(el1 === true){
			return(
				<div style={{height:350 + height / 5,zIndex:20,position:'relative'}}>
				<div className='fadeIn' style={{}}>
					<img src={halloweenLogo} alt='fweofof' style={{height:170,borderRadius:12}} />
					<div style={{height:10}}></div>
					<div style={{background:'rgb(144,205,34)',padding:10,borderRadius:12,border:'2px solid white',
							cursor:'pointer'}} className='boxShadow' onClick={()=>{window.open(appstoreLink, "_blank")}}>
						{/*<div className='heading flexCenter' style={{fontWeight:'bold'}}>Coming soon</div>
						<div style={{height:10}}></div>*/}
						<div style={{marginTop:4}}>
							<img src={appIcon} alt='fweofof' style={{height:70,borderRadius:12,border:'2px solid white',
								marginRight:10}} />
							<img src={appStore} alt='fweofof' style={{height:70,borderRadius:12,
								border:'2px solid white'}} />
						</div>
					</div>
					<div style={{height:height / 5}}></div>
				</div>
				</div>
			)
		}
	}

	// if(width < 700){
	// 	return null
	// }
	return(
		<div>
			{/*   VERSION 1   */}
			<div className=''>
				<div style={{width:'100%'}} className='flexCenter'>
					<video  autoPlay={true} loop={true} data-reactid=".0.1.0.0" id='videoWidth'
							style={{objectFit:'cover',overflow:'hidden',width:'100%',opacity:1,zIndex:1}}
							className='fullPageImage fadeIn'>
		 				 <source type="video/mp4" data-reactid=".0.1.0.0.0" src={videoLink} alt='diwef' />
					</video>
				</div>
			</div>


			<div style={{position:'absolute',zIndex:10,margin:20}}>
				<div style={{backgroundImage:'linear-gradient(rgb(220,220,220),white)',borderRadius:12,
						border:'1px solid rgb(190,190,190)',padding:6}}
						className='flexCenter boxShadow'>
					<div style={{fontSize:14}}>Brought to you by </div>
					<img src={jollydayzlogo} alt='jollydayzlogo' style={{height:24}} />
				</div>
			</div>

			<div style={{zIndex:4,position:'relative',width:'100%',height:`${height}px`}} className='flexCenter'>
				<div>
					{el1Display()}
				</div>
			</div>
			<div style={{zIndex:2,position:'relative'}}>
					
				
			
				<div style={{backgroundImage:'linear-gradient(rgb(10,1,25),black)',zIndex:20}}>
					<div style={{height:40}}></div>
					<div className='heading textCenter' style={{fontWeight:'bold',fontSize:28}}>
						A virtual Halloween, trick or treating experience</div>

					<div style={{height:80}}></div>
					<div className='riseUpStart' id='el1'>
						<div className='pageMargin flexLeft'>
							<div style={{width:'50%'}} className='flexCenter'>
								<div>
									<div style={{position:'absolute'}}>
										<img src={screenshot1} alt='wefjwe' style={{height:500,borderRadius:12}} />
									</div>
									<div style={{margin:40,marginLeft:60,marginBottom:0}}>
										<img src={screenshot4} alt='wefjwe' style={{height:500,borderRadius:12}} />
									</div>
								</div>
							</div>
							<div style={{width:'50%'}} className='flexCenter'>
								<div>
									<div className='heading textCenter'>Brand new halloween</div>
									<div style={{height:20}}></div>
									<div className='subHeading textCenter' style={{opacity:0.4}}>
										Send jump scares, collect virtual candy coins, and spend time with friends and family this holiday season</div>
									<div style={{height:140}} className='flexCenter'>
										<img src={appIcon} alt='wefhwe' style={{height:50,borderRadius:12,
											border:'1px solid white',cursor:'pointer'}}
											 onClick={()=>{window.open(appstoreLink, "_blank")}} className='boxShadow' />
									</div>
									<div className='flexCenter'>
										<img src={appStore} alt='fweofof' style={{height:40,borderRadius:12,
											border:'1px solid white',cursor:'pointer'}}
											 onClick={()=>{window.open(appstoreLink, "_blank")}} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div style={{height:120}}></div>
					<div className='riseUpStart' id='el3' style={{backgroundImage:'linear-gradient(rgba(255,245,255,0.2),rgba(255,205,255,0.2))',paddingTop:40,paddingBottom:40}}>
						<div className='pageMargin flexLeft'>
							<div style={{width:'50%'}} className='flexCenter'>
								<div>
									<div className='heading textCenter'>Safe connections</div>
									<div style={{height:20}}></div>
									<div className='subHeading textCenter' style={{opacity:0.4}}>
										Feel safe having your kids virtually trick-or-treat this halloween</div>
									<div className='subHeading textCenter' style={{opacity:0.4,marginTop:16}}>With TrikTreat, you're in control</div>
									<div style={{height:140}} className='flexCenter'>
										<img src={appIcon} alt='wefhwe' style={{height:50,borderRadius:12,
											border:'1px solid white',cursor:'pointer'}}
											 onClick={()=>{window.open(appstoreLink, "_blank")}} className='boxShadow' />
									</div>
									<div className='flexCenter'>
										<img src={appStore} alt='fweofof' style={{height:40,borderRadius:12,
											border:'1px solid white',cursor:'pointer'}}
											 onClick={()=>{window.open(appstoreLink, "_blank")}} />
									</div>
								</div>
							</div>
							<div style={{width:'50%'}} className='flexCenter'>
								<div>
									<img src={screenshot2} alt='wefjwe' style={{height:500,borderRadius:12}} className='boxShadow' />
								</div>
							</div>
						</div>
					</div>
					<div style={{height:120}}></div>
					<div className='riseUpStart' id='el4'>
						<div className='pageMargin flexLeft' >
							<div style={{width:'50%'}} className='flexCenter'>
								<div>
									<img src={screenshot3} alt='wefjwe' style={{height:500,borderRadius:12}} />
								</div>
							</div>
							<div style={{width:'50%'}} className='flexCenter'>
								<div>
									<div className='heading textCenter'>A whole world, from home</div>
									<div style={{height:20}}></div>
									<div className='subHeading textCenter' style={{opacity:0.4}}>
										Get all your kids, classmates, friends and family together in one place with TrikTreat</div>
									<div style={{height:140}} className='flexCenter'>
										<img src={appIcon} alt='wefhwe' style={{height:50,borderRadius:12,
											border:'1px solid white',cursor:'pointer'}}
											 onClick={()=>{window.open(appstoreLink, "_blank")}} className='boxShadow' />
									</div>
									<div className='flexCenter'>
										<img src={appStore} alt='fweofof' style={{height:40,borderRadius:12,
											border:'1px solid white',cursor:'pointer'}}
											 onClick={()=>{window.open(appstoreLink, "_blank")}} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div style={{height:160}}></div>


					<div style={{width:'100%'}} className='flexCenter'>
						<img style={{objectFit:'cover',overflow:'hidden',width:'100%'}}
							className='fadeIn' src={christmasLights} alt='diwef' />
					</div>
					<div style={{width:'100%'}} className='flexCenter'>
						<img style={{objectFit:'cover',overflow:'hidden',height:600}}
							className='fadeIn' src={snowmanLink} alt='diwef' />
					</div>
					{/*<div style={{borderBottom:'1px solid white'}}></div>
					<div style={{backgroundImage:'linear-gradient(rgba(255,245,255,0.2),rgba(255,205,255,0.2))',padding:40}}>
						<div className='heading textCenter' style={{fontWeight:'bold',fontSize:28}}>
							More Seasons coming!</div>
					</div>
					<div style={{borderBottom:'1px solid white'}}></div>*/}
					{/*<div style={{padding:40}}>
						<div className='heading textCenter' style={{fontWeight:'bold',fontSize:28}}>
							Other Seasons!</div>
					</div>*/}
					
					{/*<div style={{background:'black'}}>
						<Carousel>
							<div>
								<video  autoPlay={true} loop={true} muted data-reactid=".0.1.0.0"
										style={{objectFit:'cover',overflow:'hidden',width:'100%'}}>
					 				 <source type="video/mp4" data-reactid=".0.1.0.0.0" src={comingsoon1} alt='diwef' />
								</video>
							</div>
							<div>
								<video  autoPlay={true} loop={true} muted data-reactid=".0.1.0.0"
										style={{objectFit:'cover',overflow:'hidden',width:'100%'}}>
					 				 <source type="video/mp4" data-reactid=".0.1.0.0.0" src={comingsoon2} alt='diwef' />
								</video>
							</div>
							<div>
								<video  autoPlay={true} loop={true} muted data-reactid=".0.1.0.0"
										style={{objectFit:'cover',overflow:'hidden',width:'100%'}}>
					 				 <source type="video/mp4" data-reactid=".0.1.0.0.0" src={comingsoon3} alt='diwef' />
								</video>
							</div>
						</Carousel>
						<div style={{height:20}}></div>
					</div>*/}
				</div>
			</div>
			<AudioPlayer />
		</div>
	)
}

export default HomePage;