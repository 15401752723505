import React from 'react';

const CoppaForms = () => {


	function heading(input) {
		return(
			<div style={{marginBottom:20,fontSize:24,textAlign:'center',fontWeight:'bold'}} className='flexCenter'>
				{input}
			</div>
		)
	}
	function paragraph(input) {
		return(
			<div style={{marginBottom:20}}>
				{input}
			</div>
		)
	}
	function textDisplay(input) {
		return(
			<div>{input}</div>
		)
	}
	function dotPoint(input) {
		return(
			<div className='flexLeft' style={{width:'100%',marginBottom:20}}>
				<div style={{width:'6%',height:20}} className='flexCenter'>
					<div style={{height:8,width:8,borderRadius:20,background:'black',marginLeft:10}}></div>
				</div>
				<div style={{width:'94%'}}>{input}</div>
			</div>
		)
	}
	return(
		<div>

			{heading('Notice of Online Collection and Use of Personal Information of Children')}
			{paragraph('Children’s Online Privacy Protection Act (COPPA) Privacy Notice Children’s Online Privacy Protection Act (COPPA) requires that we gain verifiable consent for your child to participate  in the services we offer on our websites and through our applications (“Our Services”).  Pursuant to the terms of this notice, we are asking for your permission to collect and retain your child’s information:')}
			{dotPoint('Contact information such as: first and last name,  phone number, and email address of the parent/guardian;')}
			{dotPoint('Demographics: age, grade level and school')}
			{dotPoint('Flagging histories:  All users of the application may elect to perform a feature called “Flagging”.  Flagging enables a participant to restrict entry, or eject a participant from a public or private event.  The number of flags for a participant will be measured.')}
			{paragraph('In order to obtain this consent, we have collected your online contact information (e.g., email address) and your name.  If you do not grant consent to us to collect the personal information of your child, we will delete your contact information 60 days.')}
			{paragraph('Purposes of Collection of Personal Information: Since your child is under the age of 13, the information collected will be kept confidential in compliance with COPPA. The personal information provided by the child may be used for :')}
			{dotPoint('Delivery of the Services;')}
			{dotPoint('Monitoring the program participation;')}
			{dotPoint('Service quality and product development;')}
			{dotPoint('Contest entries and content submissions, notifying contest winners of their prizes, and handing out prizes.')}
			{paragraph('Disclosures of Personal Information of Children:  We will not require a child to disclose more information than is reasonably necessary to participate in a service or contest as a condition of participation.  No information will be disclosed to third parties; this means that information will not be shared with any businesses and/or added to any mailing lists.')}
			{paragraph('Parental Rights and Controls:  At any time, the parent or guardian can review the child’s personal information, ask to have it deleted and refuse to allow any further collection or use of the child’s information.')}
			{paragraph('Contact Information for Submitting Requests:  Parents/guardians should direct their requests concerning the personal information we keep pursuant to this notice to:')}
			{textDisplay('Andrew Marsh,')}
			{textDisplay('12444 Ventura Blvd # 203, Studio City, CA 91604')}
			{textDisplay('Email: privacy@triktreat.com')}
			<div style={{height:20}}></div>
			{paragraph('Please print out this notice and keep it for your records. I you would like to provide your consent to collection of your child’s information, please sign the attached consent form. For more information about our privacy practices, please see our privacy policy at: htts://www.triktreat.com/privacy.')}



			{heading('Parental Consent Form')}
			{paragraph('I, ____________________________, the parent or legal guardian of (________________________), have had the opportunity to review the Notice of  the privacy practices for the collection and maintenance of personal information on my child.  By signing below, I authorize my child to provide personal information to TrikTreat. I understand that in consenting to my child’s registration for the Services, I am enabling my child to participate in the services.  I also understand that it is important to provide accurate information in this consent form so that TrikTreat may contact me.')}
			{paragraph('Parent/Guardian Signature ______________________________________ Dated: __________________')}
			{paragraph('Parent/Guardian Email Address:')}
			{paragraph('Parent/Guardian Primary Phone Number:')}
			<div style={{height:20}}></div>
			{paragraph('***By entering your signature or initials you are signing this consent electronically and agree your electronic signature is the legal equivalent of a manual signature.')}



			<div style={{height:20}}></div>
			<div style={{height:20}}></div>
			<div style={{height:20}}></div>
		</div>
	)
}

export default CoppaForms;