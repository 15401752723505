import React from 'react';

import NavBar from '../../Components/NavBar/NavBar';
import PrivacyForm from '../../Components/Forms/PrivacyForm';

const Privacy = () => {
	return(
		<div>
			<NavBar />
			<div style={{height:50}}></div>
			<div className='homeBanner'>
				<div className='pageMargin'>
					<div style={{height:80}}></div>

					<PrivacyForm />
				</div>
			</div>
		</div>
	)
}

export default Privacy;