import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';

import Menu from '../../Images/menu.svg';

const NavBar = () => {
	const [ redirect, setRedirect ] = useState('');
	const [ expandMenu, setExpandMenu ] = useState(false);

	const node = useRef();
	useEffect(()=>{
		document.addEventListener('mousedown', handleClick, false);
		return () => {
			document.removeEventListener('mouseDown', handleClick, false);
		}
	},[]);

	function handleClick(e) {
		if(node.current) {
			if(node.current.contains(e.target)){
			} else {
				setExpandMenu(false);
			}
		}
	}

	function routeClicked(e) {
		if(String(window.location.pathname) === e){

		} else {
			setRedirect(e);
		}
	}


	function ExpandedMenu() {
		if(expandMenu){
			return(
				<div style={{top:50,left:0,right:0,bottom:0,
						position:'fixed',background:'rgba(0,0,0,0.3)'}}>
					<div style={{background:'black',borderTop:'1px solid rgba(255,255,255,0.2)',
							borderBottom:'1px solid rgba(255,255,255,0.2)'}} ref={node} >
						<div className='navbarItem flexCenter' onClick={()=>{routeClicked('/')}}>TrikTreat</div>
						<div className='navbarItem flexCenter' onClick={()=>{routeClicked('/terms')}}>Terms</div>
						<div className='navbarItem flexCenter' onClick={()=>{routeClicked('/privacy')}}>Privacy</div>
						<div className='navbarItem flexCenter' onClick={()=>{routeClicked('/coppa')}}>Coppa</div>
					</div>
				</div>
			)
		}
	}


	if(redirect !== ''){
		return <Redirect push to={redirect} />;
	}

	if(document.documentElement.clientWidth < 700){
		return(
			<div className='navbarContainer flexHeightCenter flexRight'>
				<img src={Menu} alt='menuItem' style={{height:32}} onClick={()=>{setExpandMenu(!expandMenu)}} />
				<div style={{width:16}}></div>
				{ExpandedMenu()}
			</div>
		)
	}
	return(
		<div className='navbarContainer flexHeightCenter'>
			<div className='pageMargin flexHeightCenter'>
				<div className='navbarItem flexCenter' onClick={()=>{routeClicked('/')}}>TrikTreat</div>
				<div className='navbarItem flexCenter' onClick={()=>{routeClicked('/terms')}}>Terms</div>
				<div className='navbarItem flexCenter' onClick={()=>{routeClicked('/privacy')}}>Privacy</div>
				<div className='navbarItem flexCenter' onClick={()=>{routeClicked('/coppa')}}>Coppa</div>
			</div>
		</div>
	)
}

export default NavBar;