import React, { useState, useRef, useEffect } from 'react';

import Pause from '../../Images/pause-circle.svg';
import Play from '../../Images/play-circle.svg';

import Song from '../../Images/Halloweentheme.mp3';

const AudioPlayer = () => {
	const [ playing, setPlaying ] = useState(false);

	const audio = useRef();
	useEffect(()=>{
		audio.current = new Audio(Song);
		audio.current.play();
		setPlaying(true);
		audio.current.addEventListener('ended', () => {setPlaying(false)} );
		return () => {
			audio.current.pause();
		}
	},[]);

	function playOrPause() {
		if(playing === true){
			setPlaying(false);
			audio.current.pause();
		} else {
			setPlaying(true);
			audio.current.play();
		}
	}


	function playOrPauseImg() {
		if(playing === false){
			return <img src={Play} alt='Play' style={{height:26}} />
		} else {
			return <img src={Pause} alt='Play' style={{height:26}} />
		}
	}

	return(
		<div style={{position:'fixed',top:0,left:0,right:0,bottom:0,zIndex:20,pointerEvents:'none'}} className='flexHeightBottom flexRight'>
			<div className='flexCenter puslingColor' style={{border:'1px solid rgba(255,255,255,0.5)',margin:'20px',padding:6,
					borderRadius:26,cursor:'pointer',pointerEvents:'auto'}} onClick={playOrPause}>
				{playOrPauseImg()}
				<div style={{marginLeft:6,fontSize:14,color:'rgba(255,255,255,0.5)'}}>Music playing</div>
				<div style={{width:6}}></div>
			</div>
		</div>
	)
}

export default AudioPlayer;