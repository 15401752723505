import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './Pages/Home/Home';
import Terms from './Pages/Terms/Terms';
import Coppa from './Pages/Coppa/Coppa';
import Privacy from './Pages/Privacy/Privacy';

import './App.css';

const App = () =>  {
	return(
		<Switch>
			<Route path='/' exact component={Home} />
			<Route path='/terms' component={Terms} />
			<Route path='/coppa' component={Coppa} />
			<Route path='/privacy' component={Privacy} />
		</Switch>
	)
}

export default App;
