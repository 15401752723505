import React, { useState, useEffect } from 'react';

import AudioPlayer from './AudioPlayer';

import halloweenLogo from '../../Images/halloweenLOGOblack2.png';
import appStore from '../../Images/appstorelink.png';
import appIcon from '../../Images/triktreatappicon.png';
import christmasLights from '../../Images/chrstmas_lights.png';
import jollydayzlogo from '../../Images/jollydayzlogo.png';

const backgroundImg = 'https://firebasestorage.googleapis.com/v0/b/triktreat-landing.appspot.com/o/mobilehomescreen.jpeg?alt=media&token=754005fa-c50a-45cc-bb8a-fb28ade02e21';
const snowmanLink = 'https://firebasestorage.googleapis.com/v0/b/triktreat-landing.appspot.com/o/snowman.jpeg?alt=media&token=088c18ca-b0c3-4ee9-a5a4-d7b26b85aed9';
const screen4 = 'https://firebasestorage.googleapis.com/v0/b/triktreat-landing.appspot.com/o/screen4mobile.jpeg?alt=media&token=f0fb4ae6-063b-4b1b-be17-5251065fab2a';
const screen5 = 'https://firebasestorage.googleapis.com/v0/b/triktreat-landing.appspot.com/o/screen2mobile.jpeg?alt=media&token=dee3165d-788e-43f1-9a28-d25aef9272cf';

let elements;
let windowHeight;

const appstoreLink = 'https://apps.apple.com/us/app/triktreat/id1535405286';

const HomePageMobile = () => {
	const [ height, setHeight ] = useState(0);
	// const [ width, setWidth ] = useState(0);
	const [ el1, setEl1 ] = useState(false);


	useEffect(()=>{
		elements = document.querySelectorAll('.riseUpStart');
    	windowHeight = window.innerHeight;
    	window.addEventListener('resize', init);
    	window.addEventListener('scroll', checkPosition);

		setHeight(document.documentElement.clientHeight);
		// setWidth(document.documentElement.clientWidth);
		setTimeout(()=>{
			setEl1(true);
		},500);
		return () => {
			// clearInterval(interval);
			window.removeEventListener('resize', init);
			window.removeEventListener('scroll', checkPosition);
		}
	},[]);

	function init() {
		setHeight(document.documentElement.clientHeight);
		// setWidth(document.documentElement.clientWidth);
	}

	function checkPosition() {
		for (var i = 0; i < elements.length; i++) {
			var element = elements[i];
			var positionFromTop = elements[i].getBoundingClientRect().top;

			if (positionFromTop - windowHeight <= 0) {
				// setTimeout(()=>{
					// element.classList.add('fadeIn');
					element.classList.add('riseUp');
					element.classList.remove('riseUpStart');
					// element.classList.remove('hidden');
				// },500);
			}
		}
	}




	function el1Display() {
		if(el1 === true){
			return(
				<div style={{height:350 + height / 5}}>
				<div className='fadeIn' style={{}}>
					<img src={halloweenLogo} alt='fweofof' style={{height:170,borderRadius:12}} />
					<div style={{height:10}}></div>
					<div className='flexCenter'>
						<div style={{background:'rgb(144,205,34)',padding:10,borderRadius:12,border:'1px solid white',
								cursor:'pointer'}} className='boxShadow' onClick={()=>{window.open(appstoreLink, "_blank")}}>
							{/*<div className='heading flexCenter' style={{fontWeight:'bold',fontSize:22}}>Coming soon</div>
							<div style={{height:10}}></div>*/}
							<div style={{marginTop:4}}>
								<img src={appIcon} alt='fweofof' style={{height:50,borderRadius:12,border:'1px solid white',
									marginRight:10}} />
								<img src={appStore} alt='fweofof' style={{height:50,borderRadius:12,
									border:'1px solid white'}} />
							</div>
						</div>
					</div>
					<div style={{height:height / 5}}></div>
				</div>
				</div>
			)
		}
	}

	// if(width < 700){
	// 	return null
	// }
	return(
		<div>
			{/*   MOBILE VERSION   */}
			{/*<div className=''>
				<div style={{}} className='flexCenter'>
					<img style={{overflow:'hidden',width:'1340px'}}
						className='fadeIn' src={Background} alt='diwef' />
				</div>
			</div>*/}
			<div style={{width:'100%',overflow:'hidden'}} className='flexCenter'>
				<img style={{objectFit:'cover',overflow:'hidden',height:height}}
						className='fadeIn' src={backgroundImg} alt='diwef' />
			</div>

			<div style={{position:'absolute',zIndex:10,margin:0,marginTop:`-${height}px`}}>
				<div style={{backgroundImage:'linear-gradient(rgb(220,220,220),white)',borderRadius:12,
						border:'1px solid rgb(190,190,190)',padding:6,margin:20}}
						className='flexCenter boxShadow'>
					<div style={{fontSize:11}}>Brought to you by </div>
					<img src={jollydayzlogo} alt='jollydayzlogo' style={{height:18}} />
				</div>
			</div>


			<div style={{zIndex:4,position:'absolute',width:'100%',height:`${height}px`,marginTop:`-${height}px`}} className='flexCenter'>
				<div>
					{el1Display()}
				</div>
			</div>
			<div style={{zIndex:2,position:'relative'}}>
					
				
			
				<div style={{backgroundImage:'linear-gradient(rgb(10,1,25),black)',zIndex:20}}>
					{/*<div className='heading textCenter' style={{fontWeight:'bold',fontSize:28}}>
						A virtual Halloween, trick or treating experience</div>*/}

					<div style={{height:80}}></div>
					<div className='riseUpStart' id='el1'>
						<div className='pageMargin'>
							
							<div style={{width:'100%'}} className='flexCenter'>
								<div>
									<div className='heading textCenter'>Brand new halloween</div>
									<div style={{height:20}}></div>
									<div className='subHeading textCenter' style={{opacity:0.4}}>
										Send jump scares, collect virtual candy coins, and spend time with friends and family this holiday season</div>
									<div style={{height:140}} className='flexCenter'>
										{/*<img src={appIcon} alt='wefhwe' style={{height:40,borderRadius:12,
												border:'1px solid white'}} className='boxShadow'
												onClick={()=>{window.open(appstoreLink, "_blank")}} />*/}
									
										<img src={appStore} alt='fweofof' style={{height:40,borderRadius:12,
											border:'1px solid white',cursor:'pointer'}}
											 onClick={()=>{window.open(appstoreLink, "_blank")}} />
									</div>
									{/*<div className='textCenter' style={{opacity:0.4,color:'white'}}>
										Coming soon</div>*/}
								</div>
							</div>
							{/*<div style={{width:'100%'}} className='flexCenter'>
								<div>
									<img src={screen1} alt='wefjwe' style={{height:500,borderRadius:12}} />
								</div>
							</div>*/}
						</div>
					</div>
					<div style={{height:10}}></div>
					<div className='riseUpStart' id='el3' style={{backgroundImage:'linear-gradient(rgba(255,245,255,0.2),rgba(255,205,255,0.2))',paddingTop:40,paddingBottom:40}}>
						<div className='pageMargin'>
							<div style={{width:'100%'}} className='flexCenter'>
								<div>
									<div className='heading textCenter'>Safe connections</div>
									<div style={{height:20}}></div>
									<div className='subHeading textCenter' style={{opacity:0.4}}>
										Feel safe having your kids virtually trick-or-treat this halloween</div>
									<div className='subHeading textCenter' style={{opacity:0.4,marginTop:16}}>With TrikTreat, you're in control</div>
									<div style={{height:30}}></div>
									{/*<div style={{height:140}} className='flexCenter'>
										<img src={appIcon} alt='wefhwe' style={{height:50,borderRadius:12,border:'1px solid white'}} className='boxShadow' />
									</div>
									<div className='textCenter' style={{opacity:0.4,color:'white'}}>
										Coming soon</div>*/}
								</div>
							</div>
							<div style={{width:'100%'}} className='flexCenter'>
								<div>
									<img src={screen4} alt='wefjwe' style={{height:500,borderRadius:12}} className='boxShadow' />
								</div>
							</div>
						</div>
					</div>
					<div style={{height:50}}></div>
					<div className='riseUpStart' id='el4'>
						<div className='pageMargin' >
							
							<div style={{width:'100%'}} className='flexCenter'>
								<div>
									<div className='heading textCenter'>A whole world, from home</div>
									<div style={{height:20}}></div>
									<div className='subHeading textCenter' style={{opacity:0.4}}>
										Get all your kids, classmates, friends and family together in one place with TrikTreat</div>
									<div style={{height:30}}></div>
									{/*<div style={{height:120}} className='flexCenter'>
										<img src={appIcon} alt='wefhwe' style={{height:50,borderRadius:12,border:'1px solid white'}} className='boxShadow' />
									</div>*/}
								</div>
							</div>
							<div style={{width:'100%'}} className='flexCenter'>
								<div>
									<img src={screen5} alt='wefjwe' style={{height:500,borderRadius:12}} />
								</div>
							</div>
						</div>
					</div>
					<div style={{height:100}}></div>

					{/*<div style={{borderBottom:'1px solid white'}}></div>
					<div style={{backgroundImage:'linear-gradient(rgba(255,245,255,0.2),rgba(255,205,255,0.2))',padding:40}}>
						<div className='heading textCenter' style={{fontWeight:'bold',fontSize:20}}>
							Other Seasons coming!</div>
					</div>
					<div style={{borderBottom:'1px solid white'}}></div>*/}

					
					<div style={{width:'100%'}} className='flexCenter'>
						<img style={{objectFit:'cover',overflow:'hidden',width:'100%'}}
							className='fadeIn' src={christmasLights} alt='diwef' />
					</div>

					<div style={{width:'100%'}} className='flexCenter'>
						<img style={{objectFit:'cover',overflow:'hidden',width:'100%'}}
							className='fadeIn' src={snowmanLink} alt='diwef' />
					</div>
					{/*<div style={{background:'black'}}>
						<Carousel>
							<div>
								<video  autoPlay={true} loop={true} muted data-reactid=".0.1.0.0" id='videoWidth'
										style={{objectFit:'cover',overflow:'hidden',width:'100%'}}>
					 				 <source type="video/mp4" data-reactid=".0.1.0.0.0" src={comingsoon1} alt='diwef' />
								</video>
							</div>
							<div>
								<video  autoPlay={true} loop={true} muted data-reactid=".0.1.0.0" id='videoWidth'
										style={{objectFit:'cover',overflow:'hidden',width:'100%'}}>
					 				 <source type="video/mp4" data-reactid=".0.1.0.0.0" src={comingsoon2} alt='diwef' />
								</video>
							</div>
							<div>
								<video  autoPlay={true} loop={true} muted data-reactid=".0.1.0.0" id='videoWidth'
										style={{objectFit:'cover',overflow:'hidden',width:'100%'}}>
					 				 <source type="video/mp4" data-reactid=".0.1.0.0.0" src={comingsoon3} alt='diwef' />
								</video>
							</div>
						</Carousel>
						<div style={{height:20}}></div>
					</div>*/}
				</div>
			</div>
			<AudioPlayer />
		</div>
	)
}

export default HomePageMobile;


