import React from 'react';

import NavBar from '../../Components/NavBar/NavBar';
import CoppaForms from '../../Components/Forms/CoppaForms';
// import CoppPdfForm from '../../Components/Forms/CoppPdfForm';
// import CoppaPdf2 from '../../Components/Forms/CoppaPdf2';

const Coppa = () => {
	return(
		<div>
			<NavBar />
			<div style={{height:50}}></div>
			<div className='homeBanner'>
				<div className='pageMargin'>
					<div style={{height:80}}></div>
					<CoppaForms />
				</div>
			</div>
		</div>
	)
}

export default Coppa;